.modals {

    &__render {
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: 10;
        background-color: rgba($black, .05);
        backdrop-filter: blur(2px);
        display: flex;
        align-items: center;
        justify-content: center;

        &--closed {
            display: none;
        }
    }

    &__window {
        min-width: 500px;
        min-height: 200px;
        max-height: calc(100% - 200px);
        cursor: initial;
        background: $white;
        padding: 20px;
    }

}

@import "./create-pack";