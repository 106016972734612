.layout {

}

.layout.layout-dashboard {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'main';
    height: 100vh;
    background: $gray-100;

    > * {
        padding: 20px 0;
    }

    > main {
        grid-area: main;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 100vh;
        position: relative;
        padding: 0;

        .content {
            padding: 20px;
        }

        .module {
            margin-bottom: 20px
        }
    }

    > nav {
        border-right: solid 1px $gray-200;
        padding: 0 12px;
        grid-area: nav;
        background: #fff;
        transition: transform 0.5s ease;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 220px;
        height: 100vh;

        position: fixed;
        overflow: hidden;

        z-index: 2;
        transform: translateX(-220px);

        &:hover {
            overflow-y: auto;
            overflow-x: hidden;
        }

        .layout-dashboard__toggle-button {
            width: 35px;
            height: 35px;
            background: $white;
            color: $secondary;
            // font-size: 1.5rem;
            line-height: 1.8rem;
            text-align: center;
            position: absolute;
            cursor: pointer;
            top: 13px;
            right: 10px;
        }

        .layout-dashboard__toggle-button:hover {
            background: $gray-100;
        }
    }

    > nav.active {
        transform: translateX(0);
    }

    @media only screen and (min-width: 1200px) {
        display: grid;
        grid-template-columns: 220px 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: 'nav main';
        height: 100vh;

        > * {
            padding: 20px;
        }

        > nav {
            display: flex;
            flex-direction: column;
            position: relative;
            transform: translateX(0);

            .layout-dashboard__toggle-button {
                display: none;
            }
        }
    }
}