.module.header {
    height: 60px;
    top: 0;
    z-index: 1;

    .header__container {
        background: $white;
        border-bottom: solid 1px $gray-200;
        height: 60px;
        display: block;
        position: relative;
        flex-direction: row;

        > * {
            display: block;
            float: left;
        }
    }

    .header__toggle-button {
        margin: 12.5px;
        width: 35px;
        height: 35px;
        background: $white;
        color: $secondary;
        font-size: 1.5rem;
        line-height: 1.8rem;
        text-align: center;
        cursor: pointer;
    }

    .header__breadcrumb {
        height: 60px;
        padding: 12.5px 20px;
        border-left: solid 1px $gray-100;
        
        .header-breadcrumb__title {
            margin: 0;
            height: 35px;
            font-size: 1.25rem;
            line-height: 35px;
        }
    }

    .header__toggle-button:hover {
        background: $gray-100;
    }

    .header__profile-menu {
        float: right;
        margin: 10px;
    }

    @media only screen and (min-width: 1200px) {
        .header__toggle-button {
            display: none;
        }
    }
}