.component.pack-item {

}


.pack-item {

    border: dashed 2px $gray-300;
    background: $gray-200;
    min-height: 300px;

    &--empty {
        display: flex;
        align-items: center;
        justify-content: center;
        .pack-item__center-text { 
            color: $white;
            font-size: 8rem;    
            user-select: none;
        }
        &:hover {
            background: $gray-300;
            border: dashed 2px $gray-400;
            cursor: pointer;
        }
    }

    

}
