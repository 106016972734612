.module.project-menu {
    width: 100%;
    position: relative;
    padding: 5px 0;
    border-bottom: solid 1px $gray-200;

    ul.project-menu__items {
        padding: 0;
        margin: 0;
        list-style: none;

        > * {
            transition: all 0.25s ease;
        }
    }
}

.module.project-menu ul.project-menu__items {

    padding: 5px 0;
    position: relative;
    display: block;

    li.menu-item {


        border-right: solid 4px $white;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        // border-left: solid 2px $gray-400;
        margin: 5px 0;

        .icon {
            margin: 2.5px;
        }

        &:hover {
            border: none;
            border-right: solid 4px $brand;
            // color: $white;
        }
    }    
}

/**
li.menu-item {
        padding: 5px;

        margin: 0px 0;
        cursor: pointer;
        line-height: 24px;
        height: 30px;

        > * {
            float: left;
            display: block;
            height: 28px;
            margin: 0 5px;
        }

        span.menu-item__label {
            font-weight: normal;
        }
        
    }

    li.menu-item:hover {
        background: $brand;
        color: $white;
    }

    li.menu-item.active {
        border-right: solid 4px $brand;
        span.menu-item__label {
            font-weight: bold;
        }
    }
**/