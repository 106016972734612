i.bi {
    font-size: 18px;
}

span.icon {
    float: left;
    height: 1.5rem;
    width: 25px;
    height: 25px;
    padding: 0px;
    display: block;
    position: relative;
    transition: none !important;
    margin: 0;
    overflow: hidden;
    text-align: center;

    * {
        transition: none !important;
    }

    i.bi {
        position: absolute;
        line-height: 1.5rem;
    }

    &--small {

        i.bi {
            top: -2px;
            left: 4px;
        }
    }

    &--medium {
        font-size: 1.25rem;

        i.bi {
            top: -3px;
            left: 4px;
        }
    }

    &--large {
        font-size: 1.5rem;
        i.bi {
            top: -3px;
            left: 2px;
        }
    }

    &--huge {
        font-size: 2rem;
        width: 50px;
        height: 50px;
        
        i.bi {
            top: -3px;
            left: 2px;
        }
    }

    &--primary {
        color: $primary;
    }

    &--secondary {
        color: $secondary;
    }

    &--warning {
        color: $warning;
    }

    &--danger {
        color: $danger;
    }

    &--success {
        color: $success;
    }

    &--info {
        color: $info;
    }

    &--interactive {
        cursor: pointer;
    }

    
    @font-face {
        font-family: 'genre_icons';
        src: url('./assets/genre_icons.eot');
        src: url('./assets/genre_icons.eot?#iefix') format('embedded-opentype'),
             url('./assets/genre_icons.woff') format('woff'),
             url('./assets/genre_icons.ttf') format('truetype'),
             url('./assets/genre_icons.svg#genre_icons') format('svg');
        font-weight: normal;
        font-style: normal;
    }
    
    &[class*='icon--genre-']:before {
        font-family: 'genre_icons';
        font-style: normal;
        font-weight: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale
    }

    &--genre {

        &-adventure:before { content:'\0041'; }
        &-board:before { content:'\0042'; }
        &-cooking:before { content:'\0043'; }
        &-crafting:before { content:'\0044'; }
        &-farm:before { content:'\0045'; }
        &-fighting:before { content:'\0046'; }
        &-gambling:before { content:'\0047' ;}
        &-ghost:before { content:'\0048'; }
        &-hidden-object:before { content:'\0049'; }
        &-love:before { content:'\004a'; }
        &-medieval:before { content:'\004b'; }
        &-multiplayer:before { content:'\004c'; }
        &-ninja:before { content:'\004d'; }
        &-novels:before { content:'\004e'; }
        &-platform:before { content:'\004f'; }
        &-puzzle:before { content:'\0050'; }
        &-resources:before { content:'\0051'; }
        &-rithm:before { content:'\0052'; }
        &-rpg:before { content:'\0053'; }
        &-science:before { content:'\0054'; }
        &-defense:before { content:'\0055'; }
        &-shooter:before { content:'\0056'; }
        &-magic:before { content:'\0057'; }
        &-stealth:before { content:'\0058'; }
        &-survival:before { content:'\0059'; }

    }
    
}

