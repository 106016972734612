.component.queued-file {
    position: relative;
    width: 100%;
    min-height: 25px;

    background: rgba($white, .3);

    display: flex;
    gap: 10px;
    border-bottom: dashed 1px $gray-300;
}

.queued-file {

    &:last-child {
        border: none;
    }

    &:first-child {
        background: rgba($white, .5);
    }

    &__type {
        user-select: none;
        width: 44px;
        max-width: 44px;
        height: 30px;
        text-transform: uppercase;

        color: $white;
        border-radius: 5px;
        opacity: .5;

        span {
            display: block;
            width: 100%;
            height: 25px;
            text-align: center;
            line-height: 30px;
        }
    }
    
    &__label {
        flex-grow: 10;
        line-height: 30px;
        font-size: .9rem;
    }

    &__size {
        width: 90px;
        max-width: 90px;

        text-align: right;
        line-height: 30px;
        font-size: .85rem;
        padding: 0 10px;
    }

    &__progress {
        padding: 2px 0;
    }
}