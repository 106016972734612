.module.main-menu {
    width: 100%;

    > div.main-menu__section {

        padding: 10px 0px 5px 0px;
        // border-bottom: solid 1px #cbd5e0;

        > div.main-menu__title {
            margin: 0;
            font-size: 0.8rem;
            text-transform: uppercase;
            line-height: 24px;
            height: 24px;
        }

        > ul.main-menu__items {
            padding: 0;
            margin: 0;
            list-style: none;

            * {
                transition: all 0.25s ease;
            }
        }
    }

    > div.main-menu__hidden {
        display: none;
    }
}

.module.main-menu ul.main-menu__items {
    li.menu-item {
        border-right: solid 4px rgba($brand, 0);

        margin: 5px 0;
        cursor: pointer;
        line-height: 30px;
        height: 30px;

        * {
            float: left;
        }

        span.menu-item__icon {
            margin: 2.5px;
            color: $body-color;
        }

        span.menu-item__label {
            color: $body-color;
            font-weight: normal;
        }
        
    }

    li.menu-item:hover {
        border-right: solid 4px rgba($brand, 1);

        span.menu-item__label {
            color: $brand;
        }
    }

    li.menu-item--active {
        border-right: solid 4px $brand;
        span.menu-item__label {
            color: $brand;
        }
    }
}