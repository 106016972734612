.module.logo {
    width: 100%;
    height: 60px;
    border-bottom: solid 1px $gray-200;

    .logo__text {
        user-select: none;
        cursor: pointer;
        padding: 0;
        margin: 0;

        text-align: left;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 26px;
        line-height: 30px;
        height: 30px;
        font-size: $font-size-lg;
        margin: 15px 0;

        > span {
            padding: 0 5px;
            font-weight: normal;
            text-decoration: underline;
        }
    }

    @media only screen and (min-width: 1200px) {
        .logo__text {
            text-align: center;
        }
    }
}