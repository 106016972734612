* {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

*::-webkit-scrollbar-track {
	// -webkit-box-shadow: inset 0 0 6px $gray;
	background-color: $gray-200;
}

*::-webkit-scrollbar {
	width: 6px;
	background-color: $white;
}

*::-webkit-scrollbar-thumb {
	background-color: $brand;
}

:root {
    color-scheme: light;
}

.btn {
    &:focus,
    &:active,
    &:active:focus,
    &.active:focus {
        outline: none;
    }
}

ul {
    padding: 0;
}

.dropdown-menu {
    
    &--empty {
       display: none !important;
    }

    &--small {
        transition: none !important;
        font-size: 0.75rem;
        user-select: none;

        padding: 2px 0;

        a {
            padding: 0 5px;
        }
    }

    &--hidden {
        visibility: hidden;
    }
    
    .dropdown-item {
        > span.icon {
            margin: 0 5px;
        }
    }
}

