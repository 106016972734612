.module.file-list {

    .file-list__create-category {
        width: 100%;
        height: 40px;
        padding: 10px 0;
        background: $gray-400;
        color: $white;
        text-align: center;
        font-size: .8rem;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            background: $gray-500;
        }
    }

    .file-list__items {
        padding: 10px 0;

        &__title {
            margin: 10px 0;
            display: flex;
            justify-content: space-between;

            .component.editable-text {
                font-size: 1.1rem;
            }

            span.icon {
                float: right;
                cursor: pointer !important;
            }

            
        }

        &--categorized {
            border: dashed 2px $gray-300;
            padding: 5px 10px;
            margin: 20px 0;
        }
    }

    

}

.module.file-list .file-list__items {
    
    * {
        transition: all 0.25s ease;
    }    
    
}