.module {

    h1 {
        height: 25px;
        font-size: 1.5rem;
        line-height: 25px;
    }

    h2 {
        height: 25px;
        font-size: 1.3rem;
        line-height: 25px;
    }

    h3 {
        height: 25px;
        font-size: 1.1rem;
        line-height: 25px;
    }

}

@import "./logo";
@import "./main-menu";
@import "./project-menu";
@import "./header";
@import "./upload-file";
@import "./file_list";
@import "./project-usage";
@import "./upload_queue";
@import "./create-project";
@import "./pack-list";