.color {
    &--gray { color: $gray-500; }
    &--red {  color: $red-500; }
    &--orange { color: $orange-500; }
    &--yellow { color: $yellow-500; }
    &--green { color: $green-500; }
    &--teal { color: $teal-500; }
    &--blue { color: $blue-500; }
    &--cyan { color: $cyan-500; }
    &--purple { color: $purple-500; }
    &--pink { color: $pink-500; }
}

.bg {
    &--gray { background: $gray-500; }
    &--red {  background: $red-500; }
    &--orange { background: $orange-500; }
    &--yellow { background: $yellow-500; }
    &--green { background: $green-500; }
    &--teal { background: $teal-500; }
    &--blue { background: $blue-500; }
    &--cyan { background: $cyan-500; }
    &--purple { background: $purple-500; }
    &--pink { background: $pink-500; }
}

.file-bg {

    width: 44px;
    max-height: 44px;

    &--binary {
        background: $blue-400;
    }
    &--image {
        background: $purple-500;
    }
    &--audio {
        background: $orange-500;
    }
    &--unknown {
        background: $gray-500;
    }
}