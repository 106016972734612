@keyframes blink {
    0% { opacity: 0; }
    25%{ opacity: .5; }
    50% { opacity: 1; }
    75% { opacity: .5; }
    100% { opacity: 0; }
}


.animate {

    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &--blink {
        animation-name: blink;
    }

}


