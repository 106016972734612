.component.file-folders {
    display: inline-block;
}

.file-folders {

    float: left;

    .badge {
        margin: 4px;
        margin-right: 5px;
        float: left;
        height: 20px;
        padding: 0 5px;
        line-height: 18px;
        user-select: none;
        box-sizing: border-box;

        border-radius: 5px;
        color: $black;
        font-weight: normal;
        border: solid 1px $gray;
        border-radius: none;
        font-size: 0.75rem;
    }

}