.component.file-tags {
    display: inline-block;
}

.file-tags {
    
    // max-width: calc(100% - 50px);

    .badge {
        margin-right: 5px;
        float: left;
        height: 20px;
        padding: 2px 5px;
        line-height: 16px;
        user-select: none;
        margin: 4px;
        
        &--tag {
            border-radius: 5px;
            background: $gray-200;
            color: $gray;
            position: relative;

            &::after {
                content: '-';
                background: $red;
                color: $white;
                position: absolute;
                border-radius: 5px;
                padding: 0 4px;
                height: 16px;
                left: 2px;
                top: 2px;
                line-height: 16px;
                visibility: hidden;
                opacity: .7;

                &:hover {
                    opacity: .9;
                }
            }

            &:hover {

                padding-left: 20px;
    
                &::after {
                    visibility: visible;
                }
            }
        }

        &--add {
            padding: 0;
            width: 20px;
            line-height: 20px;
            cursor: pointer;
            font-size: 1rem;
            font-weight: normal;
            border-radius: 5px;
            background: $gray-200;
            color: $gray;
    
            &:hover {
                background: $green;
                color: $white;
            }
        }
        


    }

    &__create {
        form {
            width: 100%;
        }

        input {
            width: calc(100% - 20px);
            padding: 0 10px;
        }
    }

    &__create-hint {
        width: 20px;
        height: 20px;
        text-align: center;
        font-size: 1.25rem;
        line-height: 1.25rem;
        color: $black;
    }

}