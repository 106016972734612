.component.project-dropdown {}

.project-dropdown {

    position: relative;

    &__button {
        cursor: pointer;
        height: 30px;
        margin: 2.5px 0;
        padding: 0;
        line-height: 24px;
        border: solid 1px $gray-300;

        // &.border {
        //     &--gray { border-bottom: solid 2px $gray-500; }
        //     &--red {  border-bottom: solid 2px $red-500; }
        //     &--orange { border-bottom: solid 2px $orange-500; }
        //     &--yellow { border-bottom: solid 2px $yellow-500; }
        //     &--green { border-bottom: solid 2px $green-500; }
        //     &--teal { border-bottom: solid 2px $teal-500; }
        //     &--blue { border-bottom: solid 2px $blue-500; }
        //     &--cyan { border-bottom: solid 2px $cyan-500; }
        //     &--purple { border-bottom: solid 2px $purple-500; }
        //     &--pink { border-bottom: solid 2px $pink-500; }
        // }

        &.dropdown-enabled {
            .project-dropdown__label {
                &::after {
                    content: '\23F7';
                    float: right;
                    padding: 0 5px;
                    color: $gray;
                    position: absolute;
                    right: 0;
                }
            }
        }
    }

    &__button .icon {
        float: left;
        display: block;
        height: 24px;
        margin: 2px 2px;
        color: $gray-600;
    }

    &__button &__label {
        float: left;
        display: block;
        border-left: solid 1px $gray-300;
        padding: 0 5px;
        height: 30px;
        width: 164px;
        line-height: 28px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &__dropdown {
        position: relative;
        width: 100%;
        user-select: none;
        padding: 2px 0;

        .dropdown-item {
            margin: 5px 0;
            &__small {
                height: 25px;
                border-top: solid 1px $gray-200;
                border-bottom: solid 1px $gray-200;

                span.icon {
                    display: none;
                }

                .project-dropdown__label {
                    text-align: center;
                    width: 100%;
                    height: 25px;
                    line-height: 25px;
                    font-size: 12px;
                }

            }

            padding: 0;
            height: 30px;
            cursor: pointer;

            span.icon {
                height: 24px;
                margin: 2px 2px;
                color: $gray-600;

                &::after {
                    display: none !important;
                }
            }

            &:active {
                span.icon {
                    color: $white !important;

                    &::after {
                        display: none !important;
                    }
                }
            }

            * {
                float: left;
                display: block;
                height: 30px;
            }
        }

    }

    &__dropdown &__label {
        padding-left: 3px;
        width: 160px;
        line-height: 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

}