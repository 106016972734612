.component.editable-text {
    width: 100%;
}

.editable-text {

    > input {
        border: none;
        background: none;
        width: 100%;
        display: block;

        &:hover {}

        &:focus-visible {
            outline: none;
        }
    }

    

}