.module.create-project {

}

.create-project {

    width: 100%;
    background: $white;
    padding: 10px;

    h2 {
        font-size: 1rem;
        text-align: center;
        max-width: 300px;
        margin: 10px auto;
        margin-top: 20px;
    }


    &__title {
        font-size: 1.5rem;
        text-align: center;
        margin: 20px 0;
        color: $gray-600;
    }

    &__name-input {

    }

    &__icon-list {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 600px;
        margin: 0 auto;

        .create-project__icon {
            
            &--selected {
                background: $black;
            }
            
            background: $gray-500;
            color: $white;
            margin: 5px;
            cursor: pointer;

            &:hover {
                background: rgba($black, 0.7);
            }
        }
    }

    &__color-list {

        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 600px;
        margin: 0 auto;

        .create-project__color {
            
            &--selected {
                border: dashed 3px rgba($black, .3);
            }
            
            width: 50px;
            height: 50px;
            border: dashed 3px rgba($black, .05);
            margin: 5px;
            cursor: pointer;

            &:hover {
                border: dashed 3px rgba($black, .3);
            }
        }

    }

}