@keyframes dual-ring-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


.component.loading {}

.loading {

    &__base {
        display: inline-block;
        width: 80px;
        height: 80px;

        &::after {
            content: " ";
            display: block;
            width: 64px;
            height: 64px;
            margin: 8px;
            border-radius: 50%;
            border: 6px solid #fff;
            border-color: #fff transparent #fff transparent;
            animation: dual-ring-rotation 1.2s linear infinite;
        }
    }
}
  