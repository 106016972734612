.data-form {

    &__section {
        min-width: 300px;
        max-width: 400px;
        margin: 20px auto;

        input,input:hover,input:active,input:focus-visible,
        select,select:hover,select:active,select:focus-visible,
        button,button:hover,button:active,button:focus-visible,
        textarea,textarea:hover,textarea:active,textarea:focus-visible {
            outline: none;
            
        }

        input,select,button,textarea {
            width: 100%;
            border: solid 1px $gray-300;
            border-radius: 0;
            padding: 10px;
            font-size: 0.875rem;
        }

        input,select,textarea {
            
            & + p {
                visibility: hidden;
            }
            
            &[data-has-error] {
                border: solid 1px $danger;
                position: relative;

                & + p {
                    font-size: 0.75rem;
                    text-transform: uppercase;
                    visibility: visible;
                    color: $danger;
                }
            }
        }

        select {
            option {
                border-radius: 0;
                padding: 5px;
            }
        }

        input:focus-visible,select:focus-visible,button:focus-visible,textarea:focus-visible {
            border: solid 1px $gray-400;
        }

        button {
            border: none;

            

            &.primary {
                background: $primary;
                color: $white;
                &:hover {
                    background: rgba($primary, .8);
                }
            }

            &.secondary {
                background: $secondary;
                color: $white;
                &:hover {
                    background: rgba($secondary, .8);
                }
            }

            &.info {
                background: $info;
                color: $white;
                &:hover {
                    background: rgba($info, .8);
                }
            }

            &.success {
                background: $success;
                color: $white;
                &:hover {
                    background: rgba($success, .8);
                }
            }

            &.warning {
                background: $warning;
                color: $white;
                &:hover {
                    background: rgba($warning, .8);
                }
            }

            &.danger {
                background: $danger;
                color: $white;
                &:hover {
                    background: rgba($danger, .8);
                }
            }
        }

        label {
            display: block;
            width: 100%;
            text-transform: uppercase;
            font-size: 0.75rem;
        }

        input {
            
        }
    }

}