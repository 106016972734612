.modal-window.create-pack {}

.create-pack {
    &__title {
        font-size: 1.5rem;
        text-align: center;
        margin: 20px 0;
        color: $gray-600;
    }

    &__file-list {
        padding: 10px 0;
        max-height: 340px;
        overflow: hidden;
        overflow-y: scroll;
    }
}