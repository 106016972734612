.module.project-usage {

    margin-top: auto;
    padding: 20px 0;

    .project-usage__block:first-child {
        border-top: solid 1px $gray-200;
    }

    .project-usage__block {
        border-bottom: solid 1px $gray-200;

        padding: 10px 0;

        h3 {
            font-size: 0.875rem;
            font-weight: normal;
            text-transform: uppercase;
            color: $gray-500;
            margin: 0;
        }

        p {
            color: $gray-600;
            margin: 0;
            font-size: 0.875rem;

            b {
                color: $black;
            }
        }

        .progress {
            height: 5px;
        }

    }


}
