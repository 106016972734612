.component.file {
    position: relative;
    width: 100%;
    min-height: 50px;

    background: $white;
    border-radius: 5px;

    display: flex;
    gap: 10px;
    margin: 10px 0;
}

.file {
    &:hover {
        background: rgba($white, .8);
        cursor: pointer;
    }

    > * {
        flex: 1 1 auto;
    }

    &__type {
        flex-shrink: 0;
        user-select: none;
        width: 50px;
        max-width: 45px;
        max-height: 45px;
        align-self: center;
        margin: 2px 5px;
        text-transform: uppercase;

        color: $white;
        border-radius: 5px;

        span {
            display: block;
            width: 100%;
            text-align: center;
            line-height: 24px;
            padding: 10px 0;
        }
    }
    
    &__label {
        flex-grow: 10;
        line-height: 22px;
        // padding: 2px 0;
        font-size: .9rem;

        &__lists {
            display: flex;
            min-height: 20px;
            overflow: hidden;
        }
    }

    &__items {
        width: 50px;
        max-width: 50px;
        height: 50px;
        
        line-height: 1.5rem;
        padding: 12px 0;
    }

    &__items a:hover * {
        text-decoration: underline;
    }

    &__size {
        width: 90px;
        max-width: 90px;

        text-align: right;
        line-height: 30px;
        padding: 10px 5px;
        font-size: .85rem;
        align-self: center;
    }

    &__menu {
        width: 60px;
        max-width: 60px;
        padding: 15px;
        overflow: hidden;

        text-align: center;

        position: relative;

        user-select: none;

        * {
            transition: none !important;
        }
        align-self: center;
    }

    .file-tags {

        &__create {

            width: 100%;
            height: 25px;
            border-bottom: dashed 1px $gray-200;

            * {
                display: block;
                float: left;
            }

            input {
                border: none;
                padding: 0 5px;
            }

            :focus-visible {
                outline: none;
            }

            span.badge {
                line-height: 18px;
                padding: 0;
                background: $success;
                color: $white;
                padding: 0 5px;
                margin: 2px 5px;
                border-radius: 5px;
            }

        }
    }
}