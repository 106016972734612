.module.upload-file {

    & > .upload-file__dropzone {
        cursor: pointer;
        width: 100%;
        padding: 40px 0;
        border: dashed 2px $gray-300;
        background: $gray-200;

        * {
            pointer-events: none;
            user-select: none;
        }
    
        > .upload-file__upload_text {
            text-align: center;
            max-width: 400px;
            margin: auto;
            border-bottom: solid 1px $gray-300;
            padding-bottom: 20px;
            color: $gray-500;

            b {
                color: $gray-600;
            }
        }

        p {
            margin: 0;
            margin-top: 20px;
            text-align: center;
            color: $gray-500;
            padding: 5px 0;
        }

        .upload-file__supported-formats {
            display: flex;
            justify-content: center;
            gap: 10px;
        }
    }

    .upload-file__format {
        color: $white;
        border-radius: 5px;
        width: 44px;
        max-width: 44px;
        height: 44px;

        span {
            text-align: center;
            width: 100%;
            padding: 10px 0;
            line-height: 24px;
            display: block;
        }
    }

    

}

